import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql, Link } from "gatsby";

import Header from "./header";
import "./layout.css";
import Footer from "./footer";
import CookieConsent from "react-cookie-consent";

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <>
        <CookieConsent
          buttonText="Ok, got it"
          style={{
            background: "#fafafa",
            color: "black",
          }}
          buttonStyle={{
            background: "#f0f0f0",
          }}
        >
          This website uses cookies. By using this website you agree to the use
          of cookies. <Link to="/cookie-policy/">Learn more.</Link>
        </CookieConsent>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div
          style={{
            margin: `0 auto`,
            // maxWidth: 1170,
            // padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 0,
          }}
        >
          <main>{children}</main>
          <Footer />
        </div>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
