import React from "react";
import { FaAngleDown } from "react-icons/fa";
import { Link } from "gatsby";
import * as styles from "./menu.module.css";
import pl from "../images/PL.ico";

const Menu = ({ className }) => (
  <ul className={`${styles.menu} ${className}`}>
    <li className={styles.dropdown}>
      <Link to="">
        Our offer <FaAngleDown />
      </Link>
      <div className={styles.dropdownContent}>
        <ul className={styles.dropdownMenu}>
          <li>
            <Link title="Company management" to="/company-management/">
              Company management
            </Link>
          </li>
          <li>
            <Link title="Sales and marketing" to="/sales-and-marketing/">
              Sales and marketing
            </Link>
          </li>
          <li>
            <Link title="B2B self-service" to="/b2b-self-service/">
              B2B self-service
            </Link>
          </li>
          <li>
            <Link title="Service management" to="/service-management/">
              Service management
            </Link>
          </li>
          <li>
            <Link title="Dedicated solutions" to="/dedicated-solutions/">
              Dedicated solutions
            </Link>
          </li>
        </ul>
      </div>
    </li>
    <li>
      <Link to="/about-us/">About us</Link>
    </li>
    <li>
      <Link className={styles.special} to="/contact/">
        Contact us
      </Link>
    </li>
    <li></li>
    <Link
      to="https://questy.pl/"
      style={{
        color: `white`,
        textDecoration: `none`,
        display: `flex`,
        alignItems: "center",
      }}
    >
      <img
        style={{
          margin: 0,
          maxWidth: "24px",
        }}
        src={pl}
      />
    </Link>
  </ul>
);

export default Menu;
