import React from "react";
import { Link } from "gatsby";
import * as styles from "./footer.module.css";

const Footer = () => {
  return (
    <footer>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "40px 0px",
        }}
        className={styles.container}
      >
        <div className={styles.footerSection}>
          <ul>
            <li>
              <Link to="/about-us/">About Us</Link>
            </li>
            <li>
              <Link to="/project-financing/">Project Financing</Link>
            </li>
            <li>
              <Link to="/contact/">Contact Us</Link>
            </li>
          </ul>
        </div>
        <div id={styles.address} className={styles.footerSection}>
          <p>Questy</p>
          <p>ul. Głogowska 31/33</p>
          <p>60-702 Poznań</p>
          <p>+48 506 504 540</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
